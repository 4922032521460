import { toggleHidden } from 'src/brandless/utils';
import { register } from 'src/brandless/ujs';

register('[data-tablist-container]', ({ element }) => {
  // Select only the parent tablist

  const tablist = element.querySelector<HTMLElement>('[role="tablist"]')!;
  const tabs = tablist.querySelectorAll<HTMLElement>('[role="tab"]');

  const panels: HTMLElement[] = Array.prototype.map.call(tabs, (tab) => {
    const id = tab.getAttribute('aria-controls');

    return document.getElementById(id);
  }) as HTMLElement[];

  const keys: Record<string, number | undefined> = {
    end: 35,
    home: 36,
    left: 37,
    up: 38,
    right: 39,
    down: 40,
    delete: 46,
    enter: 13,
    space: 32,
  };

  const direction: Record<number, number | undefined> = {
    37: -1,
    38: -1,
    39: 1,
    40: 1,
  };

  const deactivateTabs = (): void => {
    tabs.forEach((tab) => {
      tab.setAttribute('tabindex', '-1');
      tab.setAttribute('aria-selected', 'false');
      tab.classList.remove('-active');
    });

    panels.forEach((panel) => {
      panel.classList.add('hidden');
      panel.setAttribute('aria-expanded', 'false');
      toggleHidden(panel, true);
    });
  };

  const activateTab = (tab: HTMLElement, setFocus: boolean): void => {
    setFocus = setFocus || true;
    deactivateTabs();

    tab.removeAttribute('tabindex');
    tab.setAttribute('aria-selected', 'true');
    tab.classList.add('-active');

    const controls = tab.getAttribute('aria-controls')!;
    const activePanel: HTMLElement = document.getElementById(controls)!;

    activePanel.classList.remove('hidden');
    activePanel.setAttribute('aria-expanded', 'true');
    toggleHidden(activePanel, false);
  };

  const focusFirstTab = (): void => {
    (tabs[0] as HTMLElement).focus();
  };

  const focusLastTab = (): void => {
    (tabs[tabs.length - 1] as HTMLElement).focus();
  };

  const switchTabOnArrowPress = (event: KeyboardEvent): void => {
    const pressed = event.keyCode;
    const pressedDirection = direction[pressed];

    if (pressedDirection !== undefined) {
      const { target } = event;
      const index = Number((target as HTMLElement).getAttribute('data-index'));

      if (index !== undefined) {
        const activeTab = Array.prototype.find.call(
          tabs,
          (tab) => tab.getAttribute('data-index') == index + pressedDirection
        );

        if (activeTab) {
          activeTab.focus();
        } else if (pressed === keys.left || pressed === keys.up) {
          focusLastTab();
        } else if (pressed === keys.right || pressed === keys.down) {
          focusFirstTab();
        }
      }
    }
  };

  const determineOrientation = (event: KeyboardEvent): void => {
    const key = event.keyCode;
    const vertical = tablist.getAttribute('aria-orientation') == 'vertical';

    if (vertical) {
      if (key === keys.up || key === keys.down) {
        event.preventDefault();
        switchTabOnArrowPress(event);
      }
    } else {
      if (key === keys.left || key === keys.right) {
        switchTabOnArrowPress(event);
      }
    }
  };

  // EventListener: Click
  const onClick = (event: Event): void => {
    event.preventDefault();
    const tab = event.target as HTMLElement;
    activateTab(tab, false);
  };

  // Event listener: Keydown
  const onKeyDown = (event: KeyboardEvent): void => {
    const key = event.keyCode;

    switch (key) {
      case keys.end:
        event.preventDefault();
        focusLastTab();
        break;
      case keys.home:
        event.preventDefault();
        focusFirstTab();
        break;
      case keys.up:
      case keys.down:
        determineOrientation(event);
        break;
    }
  };

  // Event listener: KeyUp
  const onKeyUp = (event: KeyboardEvent): void => {
    const key = event.keyCode;

    switch (key) {
      case keys.left:
      case keys.right:
        determineOrientation(event);
        break;
      case keys.enter:
      case keys.space:
        activateTab(event.target as HTMLElement, false);
        break;
    }
  };

  // Add event listeners on each button

  tabs.forEach((tab, i) => {
    tab.addEventListener('click', onClick);
    tab.addEventListener('keydown', onKeyDown);
    tab.addEventListener('keyup', onKeyUp);

    tab.setAttribute('data-index', (i + 1).toString());
  });

  return function deregister(): void {
    tabs.forEach((tab, i) => {
      tab.removeEventListener('click', onClick);
      tab.removeEventListener('keydown', onKeyDown);
      tab.removeEventListener('keyup', onKeyUp);
    });
  };
});
